import { DestroyRef, Injectable } from '@angular/core';
import { Apollo, MutationResult, QueryRef } from 'apollo-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { GetAllUsersByAdminGQL, ToggleArchiveStatusGQL, ToggleArchiveStatusMutation, GenerateMetricsGQL, DeleteUserGQL, RefreshTokenByAdminGQL, RefreshTokenByAdminMutation, DeleteUserMutation, RefreshUserTokenByAdminGQL, RefreshUserTokenByAdminMutation, GetUsersEventsByAdminGQL, GetUsersEventsByAdminQuery, GenerateMetricsQuery, GetAllFieldsByAdminGQL, GetAllFieldsByAdminQuery, ConfirmFieldGQL } from 'src/app/services/hobbyts.service';
import { IUser } from './type';
import { ApolloQueryResult } from '@apollo/client';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';


@Injectable({
  providedIn: 'root'
})
export class AdminService {
  public userListSubject = new BehaviorSubject<IUser[]>([]);

  public userList$ = this.userListSubject.asObservable();

  constructor(
    private apollo: Apollo,
    private getAllUsersByAdminGQL: GetAllUsersByAdminGQL,
    private toggleArchiveStatusGQL: ToggleArchiveStatusGQL,
    private generateMetricsGQL: GenerateMetricsGQL,
    private deleteUserGQL: DeleteUserGQL,
    private refreshTokenByAdminGQL: RefreshTokenByAdminGQL,
    private refreshUserTokensByAdminGQL: RefreshUserTokenByAdminGQL,
    private getUsersEventsByAdminGQL: GetUsersEventsByAdminGQL,
    private destroyRef: DestroyRef,
    private getAllFieldsByAdminGQL: GetAllFieldsByAdminGQL,
    private confirmFieldGQL: ConfirmFieldGQL
  ) { }

  public getAllUsersByAdmin(): void {
    this.getAllUsersByAdminGQL.watch()
      .valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(({ data }: any) => {
        this.userListSubject.next(data ? data.getAllUsersByAdmin : []);
      });
  }

  public getUsersEventsByAdmin(userId: string): Observable<ApolloQueryResult<GetUsersEventsByAdminQuery>> {
    return this.getUsersEventsByAdminGQL
      .fetch({
        userId
      });
  }

  public toggleArchiveStatus(eventId: string, isArchived: boolean): Observable<MutationResult<ToggleArchiveStatusMutation>> {
    return this.apollo
      .mutate<ToggleArchiveStatusMutation>({
        mutation: this.toggleArchiveStatusGQL.document,
        variables: {
          eventId,
          isArchived
        }
      });
  }

  public generateMetrics(startDate: Date, endDate: Date): Observable<ApolloQueryResult<GenerateMetricsQuery>> {
    return this.generateMetricsGQL.fetch({ startDate, endDate });
  }

  public deleteUser(userId: string): Observable<MutationResult<DeleteUserMutation>> {
    return this.apollo.mutate<DeleteUserMutation>({
      mutation: this.deleteUserGQL.document,
      variables: {
        userId
      }
    });
  }

  public refreshTokenByAdmin(): Observable<MutationResult<RefreshTokenByAdminMutation>> {
    return this.apollo.mutate<RefreshTokenByAdminMutation>({
      mutation: this.refreshTokenByAdminGQL.document
    });
  }

  public refreshUserTokenByAdmin(userId: string): Observable<MutationResult<RefreshUserTokenByAdminMutation>> {
    return this.apollo.mutate<RefreshUserTokenByAdminMutation>({
      mutation: this.refreshUserTokensByAdminGQL.document,
      variables: {
        userId
      }
    });
  }

  public getAllFields(): Observable<ApolloQueryResult<GetAllFieldsByAdminQuery>> {
    return this.getAllFieldsByAdminGQL.fetch();
  }

  public confirmField(confirmFieldId: string): Observable<MutationResult<unknown>> {
    return this.apollo.mutate({
      mutation: this.confirmFieldGQL.document,
      variables: {
        confirmFieldId
      }
    })
  }
}
