import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { UpdateUserService } from 'src/app/modals/update-user-modal/update-user-modal.service';
import { MatInputModule } from '@angular/material/input';
import { IColumnToDisplay, IUser } from 'src/app/core/admin/type';

@Component({
  selector: 'hb-update-user-modal',
  templateUrl: './update-user-modal.component.html',
  standalone: true,
  styleUrls: ['./update-user-modal.component.scss'],
  imports: [
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule
  ]
})
export class UpdateUserModalComponent implements OnInit {
  public roles = ['user', 'watcher', 'organizer', 'admin'];
  public columnsToDisplay: IColumnToDisplay[] = [
    { title: 'firstName', value: $localize`First name` },
    { title: 'lastName', value: $localize`Last name` },
    { title: 'nickName', value: $localize`Nick name` },
    { title: 'role', value: $localize`Role` }
  ];
  public userForm: FormGroup;
  public user: IUser = this.data.user;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { user: IUser },
    private dialogRef: MatDialogRef<UpdateUserModalComponent>,
    private fb: FormBuilder,
    private updateUserFields: UpdateUserService
  ) {}

  public ngOnInit(): void {
    this.userForm = this.createUserForm(this.user);
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public onSubmit(userId: string, form: FormGroup, event: Event): void {
    event.preventDefault();
    this.dialogRef.close();
    this.updateUserFields.updateUserFields(userId, form.value);
  }

  private createUserForm(user: IUser): FormGroup {
    return this.fb.group({
      firstName: [
        user.firstName || '',
        [Validators.required, Validators.pattern(/^[A-Za-z]+$/), Validators.minLength(2)]
      ],
      lastName: [
        user.lastName || '',
        [Validators.required, Validators.pattern(/^[A-Za-z]+$/), Validators.minLength(2)]
      ],
      nickName: [
        user.nickName || '',
        [Validators.required, Validators.pattern(/^[\x20-\x7E]+$/), Validators.minLength(1)]
      ],
      role: [user.role || '']
    });
  }
}
