import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { GetAllUsersByAdminGQL, UpdateUserFieldsGQL } from 'src/app/services/hobbyts.service';
import { AdminService } from '../../core/admin/admin.service';
import { IUser } from 'src/app/core/admin/type';

@Injectable({
  providedIn: 'root'
})
export class UpdateUserService {
  constructor(
    private apollo: Apollo,
    private updateUserFieldsGQL: UpdateUserFieldsGQL,
    private getAllUsersByAdminGQL: GetAllUsersByAdminGQL,
    private adminService: AdminService
  ) {}

  updateUserFields(userId: string, updateFields: Record<string, any>) {
    this.apollo
      .mutate({
        mutation: this.updateUserFieldsGQL.document,
        variables: {
          userId,
          updateFields
        },
        update: (store: any, { data }: any) => {
          if (!data || !data.updateUserFields) return;

          const updatedUser = data.updateUserFields;

          const currentData: any = store.readQuery({
            query: this.getAllUsersByAdminGQL.document
          });

          const updatedData = {
            getAllUsersByAdmin: currentData.getAllUsersByAdmin.map((user: IUser) =>
              user.id === updatedUser.id ? { ...user, ...updatedUser } : user
            )
          };

          store.writeQuery({
            query: this.getAllUsersByAdminGQL.document,
            data: updatedData
          });

          this.adminService.userListSubject.next(updatedData.getAllUsersByAdmin);
        }
      })
      .subscribe();
  }
}
