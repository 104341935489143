<form [formGroup]="userForm" (ngSubmit)="onSubmit(data.user.id, userForm, $event)">
  @for (column of columnsToDisplay; track column) {
    @if (column.title !== 'role') {
      <mat-form-field>
        <mat-label i18n>{{ (column.value) }}</mat-label>
        <input matInput [formControlName]="column.title" />
        @if (userForm.get(column.title)?.hasError('pattern')) {
          <mat-error i18n>
            {{ column.value }} must contain only letters.
          </mat-error>
        }
        @if (userForm.get(column.title)?.hasError('minlength')) {
          <mat-error i18n> Minimum length is 2 characters. </mat-error>
        }
      </mat-form-field>
    }
  }

  @if(columnsToDisplay[columnsToDisplay.length - 1].title === 'role') {
    <mat-form-field>
      <mat-label i18n>Roles</mat-label>
      <mat-select formControlName="role">  
        @for(role of roles; track role) {
          <mat-option [value]="role">{{ role }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  }
  <button mat-raised-button color="primary" type="submit" [disabled]="!userForm.valid" i18n>Submit</button>
</form>
